<template>
  <main role="main" class="page-content">
    <Wizard v-if="!loading"></Wizard>
    <b-loading
      v-model="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></b-loading>
  </main>
</template>

<script>
import Wizard from '@/components/Model/Form/Wizard.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditPost',
  components: {
    Wizard,
  },
  data() {
    return {
      loading: true,
      postId: this.$route.params.postId,
      formId: this.$route.params.formId,
    }
  },
  computed: {
    ...mapState('post', {
      post: (state) => state.post,
    }),
  },
  methods: {
    ...mapActions('form', ['getPublicForm']),
    ...mapActions('post', ['getPost']),
  },
  created() {
    this.getPost(this.postId).then((result) => {
      this.getPublicForm(result.data.post.form_id).then(() => {
        let answers = result.data.post.answers
        let post = {
          id: result.data.post.id,
          attributes: {},
        }
        let attributes = {}
        answers.forEach((answer) => {
          if (this.post.medias.filter((media) => media.id == answer.id)[0]) {
            attributes[answer.form_attribute_id] = {
              id: answer.form_attribute_id,
              type_id: answer.form_attribute_type_id,
              value: this.post.medias.filter((media) => media.id == answer.id)[0]
            }
          } else {
            attributes[answer.form_attribute_id] = {
              id: answer.form_attribute_id,
              type_id: answer.form_attribute_type_id,
              value: answer.value,
            }
          }
        })
        this.$store.commit('post/setNewPost', this.post)
        post['attributes'] = attributes
        this.$store.commit('post/setPost', post)
        this.loading = false
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('post/setPost', null)
    this.$store.dispatch('map/reset')
  },
}
</script>
